import * as React from "react";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import invariant from "invariant";

const generateInitialsFromName = name => {
  const nameParts = name.split(" ");
  return nameParts
    .filter(
      (namePart, index) =>
        index === 0 || (index > 0 && index === nameParts.length - 1)
    )
    .map(namePart => namePart[0])
    .join("");
};

const useStyles = makeStyles(() => ({
  avatar: {
    height: 40,
    width: 40
  },
  chip: {
    height: 32,
    width: 32,
    fontSize: "1rem"
  }
}));

const PersonInitialsAvatar = ({ name, size, ...props }) => {
  const classes = useStyles();

  let sizeClass = null;
  switch (size) {
    case "avatar":
      sizeClass = classes.avatar;
      break;
    case "chip":
      sizeClass = classes.chip;
      break;
    default:
      sizeClass = null;
      break;
  }

  invariant(sizeClass != null, "sizeClass should not be null");

  const initials = generateInitialsFromName(name);

  return (
    <Avatar component="span" title={name} className={sizeClass} {...props}>
      {initials}
    </Avatar>
  );
};

export default PersonInitialsAvatar;
