import * as React from "react";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const pluralize = require("pluralize");

const styles = theme => ({
  divider: {
    margin: `${theme.spacing(1)}px 0px`,
    backgroundColor: theme.palette.secondary.main
  },

  descriptionList: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between"
  },

  descriptionTag: {
    fontWeight: theme.typography.fontWeightMedium
  },

  subList: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: theme.spacing(2)
  },

  title: {
    marginBottom: theme.spacing(1)
  }
});

const OrderPricingSummary = ({
  order: {
    appliedFees,
    pricingBreakdownByProduct,
    totalFeesPrice,
    totalPrice,
    totalProductsPrice,
    totalProductVariantQuantity,
    totalShippingPrice
  },
  classes
}) => (
  <div>
    <Typography variant="h6" className={classes.title}>
      Order Pricing Summary
    </Typography>
    <Card>
      <CardContent>
        <dl className={classes.descriptionList}>
          <Typography
            component="dt"
            variant="body2"
            className={classes.descriptionTag}
          >
            Total Production Pricing:
          </Typography>
          <Typography component="dd" variant="body2">
            {totalProductsPrice.formatted}
          </Typography>
        </dl>
        {pricingBreakdownByProduct.map(
          ({
            colorCount,
            product: { id, style },
            totalPrice: { formatted },
            totalProductVariantQuantity
          }) => (
            <dl className={classes.descriptionList} key={id}>
              <Typography
                component="dt"
                className={classes.subList}
                variant="body2"
              >
                {`${style} ${pluralize(
                  "piece",
                  totalProductVariantQuantity,
                  true
                )}/${pluralize("color", colorCount, true)}`}
              </Typography>
              <Typography component="dd" variant="body2" color="textSecondary">
                {formatted}
              </Typography>
            </dl>
          )
        )}
        <dl className={classes.descriptionList}>
          <Typography
            component="dt"
            variant="body2"
            className={classes.descriptionTag}
          >
            Total Art Customizations:
          </Typography>
          <Typography component="dd" variant="body2">
            {totalFeesPrice.formatted}
          </Typography>
        </dl>
        {appliedFees.map(({ amount, id, fee: { name } }) => (
          <dl className={classes.descriptionList} key={id}>
            <Typography
              component="dt"
              className={classes.subList}
              variant="body2"
            >
              {name}
            </Typography>
            <Typography component="dd" variant="body2" color="textSecondary">
              {amount.formatted}
            </Typography>
          </dl>
        ))}
        <dl className={classes.descriptionList}>
          <Typography
            component="dt"
            variant="body2"
            className={classes.descriptionTag}
          >
            Shipping Total:
          </Typography>
          <Typography component="dd" variant="body2">
            {totalShippingPrice.formatted}
          </Typography>
        </dl>
        <Divider className={classes.divider} />
        <Typography variant="body2" align="center">
          Total Order Value
        </Typography>
        <Typography component="p" variant="subtitle2" align="center">
          {`${pluralize("piece", totalProductVariantQuantity, true)} | ${
            totalPrice.formatted
          }`}
        </Typography>
      </CardContent>
    </Card>
  </div>
);

export default withStyles(styles)(OrderPricingSummary);
