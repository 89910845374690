import * as React from "react";
import { getIconForProductionJobMethod } from "../../helpers/getIconForProductionJobMethod";
import { makeStyles } from "@mui/styles";
import { useMemo } from "react";
import Badge from "@mui/material/Badge";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const getColorForStatus = status => {
  switch (status) {
    case "COMPLETE": {
      return "primary";
    }
    case "IN_PROGRESS": {
      return "secondary";
    }
    case "ON_HOLD": {
      return "error";
    }
    default: {
      return "default";
    }
  }
};

const ProductionMethodIconSet = ({ productionMethodStatuses }) => {
  const classes = useStyles();

  const orderMethods = useMemo(() => {
    if (!productionMethodStatuses) return [];

    const {
      directToGarmentStatus,
      highDefinitionDigitalStatus,
      embroideryStatus,
      screenPrintingStatus,
      vinylStatus
    } = productionMethodStatuses;

    const notApplicable = "NOT_APPLICABLE";
    const methods = [];

    if (directToGarmentStatus !== notApplicable) {
      methods.push({
        method: "DIRECT_TO_GARMENT",
        status: directToGarmentStatus
      });
    }

    if (highDefinitionDigitalStatus !== notApplicable) {
      methods.push({
        method: "HIGH_DEFINITION_DIGITAL",
        status: highDefinitionDigitalStatus
      });
    }

    if (embroideryStatus !== notApplicable) {
      methods.push({ method: "EMBROIDERY", status: embroideryStatus });
    }

    if (screenPrintingStatus !== notApplicable) {
      methods.push({ method: "SCREEN_PRINTING", status: screenPrintingStatus });
    }

    if (vinylStatus !== notApplicable) {
      methods.push({ method: "VINYL", status: vinylStatus });
    }

    return methods;
  }, [productionMethodStatuses]);

  return (
    <div>
      {orderMethods.map(({ method, status }, index) => {
        const hasReadyStatus = status === "READY";
        const methodIcon = getIconForProductionJobMethod(method, index);
        return (
          status &&
          methodIcon && (
            <Badge
              badgeContent={null}
              className={classes.icon}
              key={index}
              variant="dot"
              invisible={hasReadyStatus}
              color={getColorForStatus(status)}
            >
              {methodIcon}
            </Badge>
          )
        );
      })}
    </div>
  );
};

export default ProductionMethodIconSet;
