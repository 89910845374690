import * as React from "react";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTableCell from "../../../../../components/CustomTableCell";
import PriorityChip from "../../../../../components/PriorityChip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import useScrollPosition from "../../../../../hooks/useScrollPosition";

const useStyles = makeStyles(theme => ({
  action: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2)
  },

  contactWrapper: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  contactColumn: {
    width: 150,
    margin: "0 auto",
    wordWrap: "break-word",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20%",
      marginRight: theme.spacing(1)
    }
  },

  screensMadeColumn: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0
    }
  },

  dateColumn: {
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  }
}));

const ScreensMadeTable = ({
  loadingOrderIds,
  onRequestMakeScreen,
  onRequestSort,
  onScrolledToBottom,
  orders
}) => {
  const classes = useStyles();

  const { isScrolledToBottom } = useScrollPosition();
  useEffect(() => {
    if (isScrolledToBottom) {
      onScrolledToBottom();
    }
  });

  // Handlers:
  const handleMakeScreenButtonClick = event => {
    onRequestMakeScreen(event.currentTarget.id);
  };

  const handleSortRequest = event => {
    onRequestSort(event.currentTarget.id);
  };

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell className={classes.contactColumn}>
              <div className={classes.contactWrapper}>
                <Button id="CUSTOMER_NAME" onClick={handleSortRequest}>
                  <Typography color="textSecondary" variant="overline">
                    Customer/Order #
                  </Typography>
                </Button>
              </div>
            </CustomTableCell>
            <CustomTableCell align="center" className={classes.dateColumn}>
              <Button id="PRODUCTION_DATE" onClick={handleSortRequest}>
                <Typography color="textSecondary" variant="overline">
                  Production Date
                </Typography>
              </Button>
            </CustomTableCell>
            <CustomTableCell
              align="center"
              className={classes.screensMadeColumn}
            >
              <Typography color="textSecondary" variant="overline">
                Screens Made
              </Typography>
            </CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(order => (
            <TableRow key={order.id}>
              <TableCell className={classes.contactColumn} scope="row">
                <div className={classes.contactWrapper}>
                  <Typography variant="h6">
                    {order.primaryCustomer.name}
                  </Typography>
                  <Typography color="textSecondary">
                    {order.orderNumber}
                  </Typography>
                </div>
              </TableCell>
              <TableCell align="center" className={classes.dateColumn}>
                {order.productionDate && (
                  <PriorityChip
                    date={order.productionDate}
                    priority={order.priority}
                  />
                )}
              </TableCell>
              <TableCell
                align="center"
                className={classNames(
                  classes.action,
                  classes.screensMadeColumn
                )}
              >
                {loadingOrderIds.includes(order.id) ? (
                  <CircularProgress />
                ) : (
                  <Button
                    id={order.id}
                    variant="contained"
                    color="primary"
                    onClick={handleMakeScreenButtonClick}
                  >
                    Made
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default ScreensMadeTable;
