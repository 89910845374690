import * as React from "react";

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = React.useState({
    isScrolledToBottom: false
  });

  const isDocumentScrolledToBottom = () => {
    const documentElement = document.documentElement;
    const documentBody = document.body;

    if (!documentElement || !documentBody) {
      return;
    }

    const scrollTop = documentBody.scrollTop;
    const otherScrollTop = documentElement.scrollTop;
    return (
      window.innerHeight + scrollTop + otherScrollTop ===
      documentElement.offsetHeight
    );
  };

  const newScrollPosition = isDocumentScrolledToBottom();
  if (newScrollPosition !== scrollPosition.isScrolledToBottom) {
    setScrollPosition({ isScrolledToBottom: newScrollPosition });
  }

  React.useEffect(() => {
    const handleWindowScroll = () => {
      setScrollPosition({ isScrolledToBottom: isDocumentScrolledToBottom() });
    };

    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
