import * as React from "react";
import { DateTime } from "luxon";
import { withStyles } from "@mui/styles";

import Typography from "@mui/material/Typography";

const styles = () => ({
  noteAuthorStyles: {
    fontSize: "0.650rem",
    color: "#808080"
  }
});

const AutomatedNotesBreakdown = ({ classes, notesCollection }) => (
  <React.Fragment>
    {notesCollection.length > 0 ? (
      notesCollection.map((note, index) => (
        <div key={`note-${index}`}>
          {note.__typename === "CountValidatedNote" && (
            <Typography>{`${note.label}`}</Typography>
          )}

          {note.__typename === "ProductionFileLocationNote" && (
            <Typography>{`${note.filePath} `}</Typography>
          )}

          {note.__typename === "StationAssignmentNote" && (
            <Typography>{`${note.station} ${note.employeeName} `}</Typography>
          )}

          {note.__typename === "ScreensMadeNote" && (
            <Typography>Screens Made</Typography>
          )}

          {note.__typename === "StockReceivedNote" && (
            <Typography>{`${note.label}`}</Typography>
          )}
          {note.__typename === "StockStagedNote" && (
            <Typography>{`${note.labels}`}</Typography>
          )}
          <Typography className={classes.noteAuthorStyles}>
            {`-${note.author} (${DateTime.fromISO(note.createdAt, {
              setZone: true
            }).toLocaleString(DateTime.DATETIME_SHORT)})`}
          </Typography>
          <br />
        </div>
      ))
    ) : (
      <Typography> N/A </Typography>
    )}
  </React.Fragment>
);

export default withStyles(styles)(AutomatedNotesBreakdown);
