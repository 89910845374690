import * as React from "react";
import { DateTime } from "luxon";
import { ThemeContextConsumer } from "../../../../../application/themeContext";
import { fragments as automatedNotesWrapperFragments } from "../../../../../components/AutomatedNotesWrapper/graph";
import { filter } from "graphql-anywhere";
import { getLogoForBrandUrl } from "../../../../../helpers";
import { makeStyles } from "@mui/styles";
import { fragments as notesWrapperFragments } from "../../../../../components/NotesWrapper/graph";
import { priorityOrder } from "../../../../../helpers/priorityOrder";
import { fragments as productionJobLineItemBreakdownTableFragments } from "../../../../../components/ProductionJobLineItemBreakdownTable/graph";
import { query } from "./graph";
import { fragments as styleSubstitutionLineItemBreakdownFragments } from "../../../../../components/StyleSubstitutionLineItemBreakdown/graph";
import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import AutomatedNotesWrapper from "../../../../../components/AutomatedNotesWrapper";
import Button from "@mui/material/Button";
import Checked from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import CustomerStatusChip from "../../../../apps/OrderApp/components/HeaderToolbar/components/CustomerStatusChip";
import Divider from "@mui/material/Divider";
import ExpeditingLabelViewer from "../../../../../components/ExpeditingLabelViewer";
import NoBrandTagIcon from "../../../../../assets/no-brand-tag.png";
import NotesWrapper from "../../../../../components/NotesWrapper";
import OrderStatusHistorySummary from "../../../../apps/OrderApp/components/OrderStatusHistorySummary";
import PendingStatusView from "../../../../../components/PendingStatusView";
import PriorityChip from "../../../../../components/PriorityChip";
import ProductionJobLineItemBreakdownTable from "../../../../../components/ProductionJobLineItemBreakdownTable";
import StyleSubstitutionLineItemBreakdown from "../../../../../components/StyleSubstitutionLineItemBreakdown";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

const pluralize = require("pluralize");

const useStyles = makeStyles(theme => ({
  button: {
    marginBottom: theme.spacing(2),
    width: "100%"
  },

  descriptionList: {
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(2)
  },

  descriptionTag: {
    marginRight: theme.spacing(1)
  },

  divider: {
    margin: `${theme.spacing(2)} 0px`
  },

  loadingWrapper: {
    display: "flex",
    justifyContent: "center"
  },

  notesHistoryContainer: {
    flex: "1 1 25%",
    overflowY: "scroll"
  },

  orderNumberTotalBlindShippedWrapper: {
    alignItems: "end",
    display: "flex",
    justifyContent: "space-between"
  },

  orderStatusHistoryTitle: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  primaryCustomerInfoWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },

  primaryCustomerName: {
    marginRight: theme.spacing(1)
  },

  productionDueDateWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  productionJobLabel: {
    fontWeight: theme.typography.fontWeightMedium
  },

  productionJobLineItemBreakdownTable: {
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },

  tab: {
    color:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0.7)"
        : "rgba(0, 0, 0, 0.54)",
    margin: theme.spacing(1)
  },

  wrapper: {
    padding: theme.spacing(2)
  }
}));

const isBlindShipped = "BLIND_SHIPPED";

const OrderDetailView = ({
  autoPrint,
  isStagingFromStock,
  onRequestStockStaged,
  onRequestUnstaged,
  orderId
}) => {
  const classes = useStyles();

  const { data, loading, error } = useQuery(query, {
    variables: { orderId },
    skip: !orderId
  });

  // States:
  const [selectedAuxiliaryTabIndex, setSelectedAuxiliaryTabIndex] = useState(0);

  // Handlers:
  let printRef;
  const handlePrintRef = ref => (printRef = ref);

  const handleAuxiliaryTabChange = useCallback((event, index) => {
    setSelectedAuxiliaryTabIndex(index);
  }, []);

  const handleStockStagedButtonClick = useCallback(
    event => {
      event.stopPropagation();
      onRequestStockStaged(event.currentTarget.id);
    },
    [onRequestStockStaged]
  );

  const handleStockUnstagedButtonClick = useCallback(
    event => {
      event.stopPropagation();
      onRequestUnstaged(event.currentTarget.id);
    },
    [onRequestUnstaged]
  );

  const handlePrintLabelButtonClick = event => {
    event.stopPropagation();
    if (printRef) {
      printRef();
    }
  };

  const lineItems = useMemo(() => {
    let lineItems = [];
    if (!loading && data?.order) {
      for (const productionJob of data?.order?.productionJobs) {
        for (const customizationArea of productionJob.customizationAreas) {
          if (customizationArea.location === "Transfers Only") {
            let label = customizationArea.method;
            if (label === "TRANSFER_STANDARD") {
              label = "Transfers - Standard";
            } else if (label === "TRANSFER_GLITTER") {
              label = "Transfers - Glitter";
            } else if (label === "TRANSFER_UV") {
              label = "Transfers - UV";
            }
            lineItems.push({
              quantity: customizationArea.quantity,
              label: label
            });
          }
        }
        for (const lineItem of productionJob.lineItemBreakdownByProduct) {
          if (lineItem.product.style !== "Ninja Transfer") {
            lineItems.push({
              quantity: lineItem.totalProductVariantQuantity,
              label: lineItem.product.fullDisplayName
            });
          }
        }
      }
    }
    return lineItems;
  }, [data, loading]);

  if (loading) return <PendingStatusView status="Loading" />;
  if (error) {
    return (
      <Typography variant="body2" color="error">
        {error.message}
      </Typography>
    );
  }
  if (!data) return null;

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.primaryCustomerInfoWrapper}>
          <div style={{ height: "34px", maxWidth: "95px", marginRight: "8px" }}>
            {data.order.primaryCustomer.businessUnit.name && (
              <ThemeContextConsumer>
                {context => (
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%"
                    }}
                    src={getLogoForBrandUrl(
                      data.order.primaryCustomer.businessUnit.name,
                      context.type
                    )}
                    alt={data.order.primaryCustomer.businessUnit.name}
                  />
                )}
              </ThemeContextConsumer>
            )}
          </div>

          <CustomerStatusChip status={data.order.primaryCustomer.status} />
          {!data.order.primaryCustomer.insideBrandTagging && (
            <img
              style={{ width: "36px", margin: "0 8px" }}
              src={NoBrandTagIcon}
              alt="No Brand Tagging"
            />
          )}
        </div>

        <div className={classes.primaryCustomerInfoWrapper}>
          <Typography className={classes.primaryCustomerName} variant="body1">
            {data.order.primaryCustomer.name}
            {` (${data.order.primaryCustomer.totalOrders}) `}
          </Typography>
        </div>
      </div>
      <div className={classes.orderNumberTotalBlindShippedWrapper}>
        <div>
          <Typography color="textSecondary" variant="subtitle2">
            {data.order.orderNumber}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {`${pluralize(
              "Piece",
              data.order.totalProductVariantQuantity,
              true
            )}`}
          </Typography>
        </div>
        <div className={classes.descriptionList}>
          {data.order.primaryCustomer.status === isBlindShipped && (
            <React.Fragment>
              <Typography
                className={classes.descriptionTag}
                color="textSecondary"
                variant="subtitle2"
              >
                Blind Shipped:
              </Typography>
              <Typography variant="subtitle2">
                <Checked />
              </Typography>
            </React.Fragment>
          )}
        </div>
      </div>
      <Divider light={true} className={classes.divider} />
      {isStagingFromStock ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <React.Fragment>
          <ExpeditingLabelViewer
            autoPrint={autoPrint}
            isResolution={data.order.isResolution}
            productionJobs={data.order.productionJobs}
            totalProductionJobs={data.order.productionJobs.length}
            printRef={handlePrintRef}
            hidden={true}
            priority={priorityOrder(
              data.order.productionDate,
              data.order.dueBy.dateTime
            )}
            includeCatalogInShipment={data.order.includeCatalogInShipment}
            productionStationAssignment={
              data.order.productionStationAssignments.length > 0
                ? data.order.productionStationAssignments[0].productionStation
                    .name
                : undefined
            }
            shipMissCosts={data.order.shipMissCosts}
            shippingMethodName={
              data.order.shippingTransactions.length
                ? data.order.shippingTransactions[0].shippingMethod?.name
                : null
            }
            lineItems={lineItems}
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handlePrintLabelButtonClick}
          >
            Print Label
          </Button>
          {data.order.isStockStaged ? (
            <Button
              className={classes.button}
              id={data.order.id}
              variant="contained"
              color="secondary"
              onClick={handleStockUnstagedButtonClick}
            >
              Unstage
            </Button>
          ) : (
            <Button
              className={classes.button}
              id={data.order.id}
              variant="contained"
              color="primary"
              onClick={handleStockStagedButtonClick}
            >
              Staged
            </Button>
          )}
        </React.Fragment>
      )}
      <div className={classes.productionDueDateWrapper}>
        <div className={classes.descriptionList}>
          <Typography
            className={classes.descriptionTag}
            color="textSecondary"
            variant="subtitle2"
          >
            Production Date:
          </Typography>
          <Typography variant="subtitle2">
            {data.order.productionDate && (
              <PriorityChip
                date={data.order.productionDate}
                priority={data.order.priority}
              />
            )}
          </Typography>
        </div>
        <div className={classes.descriptionList}>
          <Typography
            className={classes.descriptionTag}
            color="textSecondary"
            variant="subtitle2"
          >
            Due Date:
          </Typography>
          <Typography variant="subtitle2">
            {DateTime.fromISO(data.order.dueBy.dateTime).toLocaleString(
              DateTime.DATE_SHORT
            )}
          </Typography>
        </div>
      </div>
      {data.order.productionStationAssignments.map((assignment, index) => (
        <div className={classes.descriptionList} key={index}>
          <Typography
            className={classes.descriptionTag}
            color="textSecondary"
            variant="subtitle2"
          >
            Station Assignment:
          </Typography>
          <Typography variant="subtitle2">
            {assignment.productionStation.name}
          </Typography>
        </div>
      ))}
      <Divider light={true} className={classes.divider} />
      {data.order.productionJobs.map(productionJob => (
        <React.Fragment key={productionJob.id}>
          <Typography className={classes.productionJobLabel} variant="body1">
            {productionJob.label}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {`${pluralize(
              "Piece",
              productionJob.totalProductVariantQuantity,
              true
            )}`}
          </Typography>
          {productionJob.stockContainers.length > 0 && (
            <React.Fragment>
              <Divider light={true} className={classes.divider} />
              <Typography
                className={classes.productionJobLabel}
                variant="body1"
              >
                Stock Containers ({productionJob.stockContainers.length})
              </Typography>
              {productionJob.stockContainers.map(
                ({ latestLocation, id }, index) => (
                  <Typography
                    color="textSecondary"
                    key={id}
                    variant="subtitle2"
                  >
                    {index + 1} -{" "}
                    {latestLocation
                      ? latestLocation.location
                      : `Location
                 Unknown`}
                  </Typography>
                )
              )}
              <Divider light={true} className={classes.divider} />
            </React.Fragment>
          )}
          {productionJob.lineItemBreakdownByProduct.map(
            productionJobLineItemBreakdown => (
              <div
                className={classes.productionJobLineItemBreakdownTable}
                key={productionJobLineItemBreakdown.product.id}
              >
                <Typography variant="body2">
                  {`${productionJobLineItemBreakdown.product.style} - `}
                  {productionJobLineItemBreakdown.product.fullDisplayName}
                </Typography>
                {productionJobLineItemBreakdown.product.isStyleSubstitution ? (
                  <StyleSubstitutionLineItemBreakdown
                    lineItems={filter(
                      styleSubstitutionLineItemBreakdownFragments.lineItems,
                      productionJobLineItemBreakdown.lineItems
                    )}
                  />
                ) : (
                  <ProductionJobLineItemBreakdownTable
                    lineItems={filter(
                      productionJobLineItemBreakdownTableFragments.lineItems,
                      productionJobLineItemBreakdown.lineItems
                    )}
                  />
                )}
              </div>
            )
          )}
        </React.Fragment>
      ))}
      <Divider light={true} className={classes.divider} />
      <div className={classes.notesHistoryContainer}>
        <Tabs
          indicatorColor="primary"
          onChange={handleAuxiliaryTabChange}
          scrollButtons="auto"
          textColor="primary"
          value={selectedAuxiliaryTabIndex}
          variant="scrollable"
        >
          <Tab className={classes.tab} label="Notes" />
          <Tab
            className={classes.tab}
            label={`Automated Notes (${data.order.automatedNotesCollection.length})`}
          />
          <Tab className={classes.tab} label="Status History" />
        </Tabs>
        {selectedAuxiliaryTabIndex === 0 && (
          <div>
            <NotesWrapper
              order={filter(notesWrapperFragments.order, data.order)}
            />
          </div>
        )}
        {selectedAuxiliaryTabIndex === 1 && (
          <div>
            <AutomatedNotesWrapper
              order={filter(automatedNotesWrapperFragments.order, data.order)}
            />
          </div>
        )}
        {selectedAuxiliaryTabIndex === 2 && (
          <div>
            <Typography
              className={classes.orderStatusHistoryTitle}
              variant="h6"
            >
              Order Status History
            </Typography>
            <OrderStatusHistorySummary orderId={data.order.id} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetailView;
