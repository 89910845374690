import * as React from "react";
import printAreaIconsBackNeckline from "../assets/printAreaIcons/backNeckline.png";
import printAreaIconsFullBack from "../assets/printAreaIcons/fullBack.png";
import printAreaIconsFullFront from "../assets/printAreaIcons/fullFront.png";
import printAreaIconsHatBack from "../assets/printAreaIcons/hatBack.png";
import printAreaIconsHatFront from "../assets/printAreaIcons/hatFront.png";
import printAreaIconsInsideTagPrint from "../assets/printAreaIcons/insideTagPrint.png";
import printAreaIconsLeftChest from "../assets/printAreaIcons/leftChest.png";
import printAreaIconsLeftSide from "../assets/printAreaIcons/leftSide.png";
import printAreaIconsLeftSleeve from "../assets/printAreaIcons/leftSleeve.png";
import printAreaIconsRightChest from "../assets/printAreaIcons/rightChest.png";
import printAreaIconsRightSide from "../assets/printAreaIcons/rightSide.png";
import printAreaIconsRightSleeve from "../assets/printAreaIcons/rightSleeve.png";

const getIconForCustomizationAreaTemplate = customizationArea => {
  switch (customizationArea) {
    case "Front":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsFullFront}
        />
      );
    case "Front-Left Chest":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsLeftChest}
        />
      );
    case "Front-Right Chest":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsRightChest}
        />
      );
    case "Back":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsFullBack}
        />
      );
    case "Back Neckline":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsBackNeckline}
        />
      );
    case "Inside Tag Line":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsInsideTagPrint}
        />
      );
    case "Sleeve Left":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsLeftSleeve}
        />
      );
    case "Sleeve Right":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsRightSleeve}
        />
      );
    case "Hat Front":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsHatFront}
        />
      );
    case "Hat Back":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsHatBack}
        />
      );
    case "Side Left":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsLeftSide}
        />
      );
    case "Side Right":
      return (
        <img
          alt="customization-area-icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
          src={printAreaIconsRightSide}
        />
      );

    default:
      return null;
  }
};

export { getIconForCustomizationAreaTemplate };
