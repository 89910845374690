import * as React from "react";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const styles = theme => ({
  colorName: {
    flex: "1 1 auto"
  },

  colorSwatch: {
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: 3,
    flex: "0 0 auto",
    height: 20,
    marginRight: theme.spacing(0.5),
    width: 20
  },

  container: {
    alignItems: "center",
    display: "flex"
  }
});

const InkColorDetails = ({ inkColor, pantoneColor, classes }) => (
  <span className={classes.container}>
    {inkColor && pantoneColor ? (
      <React.Fragment>
        <span
          className={classes.colorSwatch}
          style={{
            background: inkColor.color.formatted
          }}
        />
        <Typography variant="subtitle2" className={classes.colorName}>{`${
          inkColor.name
        } | ${pantoneColor.code}`}</Typography>
      </React.Fragment>
    ) : inkColor && !pantoneColor ? (
      <React.Fragment>
        <span
          className={classes.colorSwatch}
          style={{
            background: inkColor.color.formatted
          }}
        />
        <Typography variant="subtitle2" className={classes.colorName}>
          {inkColor.name}
        </Typography>
      </React.Fragment>
    ) : (
      !inkColor &&
      pantoneColor && (
        <React.Fragment>
          <span
            className={classes.colorSwatch}
            style={{
              background: pantoneColor.color.formatted
            }}
          />
          <Typography variant="subtitle2" className={classes.colorName}>
            {pantoneColor.code}
          </Typography>
        </React.Fragment>
      )
    )}
  </span>
);

export default withStyles(styles)(InkColorDetails);
