import { DateTime } from "luxon";

const priorityOrder = (productionDate, dueDate) => {
  if (!dueDate) {
    return false;
  }

  const productionDay = DateTime.fromISO(productionDate);
  const dueDay = DateTime.fromISO(dueDate).startOf("day");

  let lastShippingDate = dueDay.minus({ days: 1 });
  let count = 0;

  while (lastShippingDate.weekday >= 6) {
    lastShippingDate = lastShippingDate.minus({ days: 1 });
    count++;

    if (count > 7) {
      break;
    }
  }

  return productionDay.valueOf() >= lastShippingDate.valueOf();
};

export { priorityOrder };
