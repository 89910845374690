const availableCustomizationAreaMethods = [
  { id: "SCREEN_PRINTING", label: "Screen printing" },
  { id: "DIRECT_TO_GARMENT", label: "Direct-to-garment" },
  { id: "EMBROIDERY", label: "Embroidery" },
  { id: "PRINTED_VINYL", label: "Printed vinyl" },
  { id: "CUT_VINYL", label: "Cut vinyl" },
  { id: "TACKLE_TWILL", label: "Tackle twill" },
  { id: "HEAT_PRESS", label: "Heat press" },
  { id: "SUBLIMATION", label: "Sublimation" }
];

export { availableCustomizationAreaMethods };
