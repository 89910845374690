const getAttributesList = (
  autobase,
  distressed,
  flatten,
  halftones,
  imageRepair,
  recreate,
  sponsorback
) => {
  let list = [];

  if (autobase) {
    list.push("Autobase");
  }
  if (distressed) {
    list.push("Distressed");
  }
  if (flatten) {
    list.push("Flatten");
  }
  if (halftones) {
    list.push("Halftones");
  }
  if (imageRepair) {
    list.push("Image Repair");
  }
  if (recreate) {
    list.push("Recreate");
  }
  if (sponsorback) {
    list.push("Sponsorback");
  }

  return list.join(", ");
};

export { getAttributesList };
