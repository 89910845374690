import { gql } from "@apollo/client";

const markOrderAsScreensMadeMutation = gql`
  mutation($orderId: ID!) {
    markOrderAsScreensMade(orderId: $orderId) {
      errors {
        orderId
      }
      order {
        id
      }
      succeeded
    }
  }
`;

export default markOrderAsScreensMadeMutation;
