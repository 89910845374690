import * as React from "react";
import { withStyles } from "@mui/styles";
import AutomatedNotesBreakdown from "./components/AutomatedNotesBreakdown";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const styles = theme => ({
  divider: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  noteContainer: {
    padding: theme.spacing(2)
  },

  noteTitle: {
    marginBottom: theme.spacing(2)
  }
});

const AutomatedNotesWrapper = ({
  classes,
  order: { automatedNotesCollection }
}) => (
  <div className={classes.noteContainer}>
    <Typography className={classes.noteTitle} variant="h6">
      Automated Notes
    </Typography>
    <AutomatedNotesBreakdown notesCollection={automatedNotesCollection} />
    <Divider className={classes.divider} light />
  </div>
);

export default withStyles(styles)(AutomatedNotesWrapper);
