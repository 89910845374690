const getProductionMethodOptionAvailability = method => {
  const isDTG = method === "DIRECT_TO_GARMENT";
  const isHDD = method === "HIGH_DEFINITION_DIGITAL";
  const isEmbroidery = method === "EMBROIDERY";
  const isScreenPrinting = method === "SCREEN_PRINTING";
  const isSublimation = method === "SUBLIMATION";
  const isTackleTwill = method === "TACKLE_TWILL";
  const isCutVinyl = method === "CUT_VINYL";
  const isPrintedVinyl = method === "PRINTED_VINYL";

  return {
    autobaseOptionIsAvailable: isScreenPrinting,
    colorOptionIsAvailable: isScreenPrinting || isEmbroidery || isTackleTwill,
    distressedOptionIsAvailable:
      isScreenPrinting || isDTG || isHDD || isSublimation,
    flattenOptionIsAvailable:
      isScreenPrinting ||
      isCutVinyl ||
      isPrintedVinyl ||
      isEmbroidery ||
      isDTG ||
      isHDD,
    halftonesOptionIsAvailable: isScreenPrinting,
    imageRepairOptionIsAvailable:
      isScreenPrinting || isCutVinyl || isPrintedVinyl || isDTG || isHDD,
    recreateOptionIsAvailable:
      isScreenPrinting ||
      isCutVinyl ||
      isPrintedVinyl ||
      isEmbroidery ||
      isDTG ||
      isHDD,
    sponsorbackOptionIsAvailable: isScreenPrinting || isDTG || isHDD,
    isCutVinyl
  };
};

export { getProductionMethodOptionAvailability };
