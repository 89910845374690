import * as React from "react";
import { DateTime } from "luxon";
import { filter } from "graphql-anywhere";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  markOrderAsStockStagedMutation,
  markOrderAsUnstagedMutation
} from "../../../graph";
import { playAudioForReturnStock } from "../../../helpers/playAudioForReturnStock";
import { query } from "./graph";
import { fragments as stockStagedTableFragments } from "./components/StockStagedTable/graph";
import { useEffect, useMemo, useState } from "react";
import { useRouter } from "found";
import BarcodeReader from "react-barcode-reader";
import CenteredSpinner from "../../../components/CenteredSpinner";
import MenuItem from "@mui/material/MenuItem";
import OrderDetailView from "./components/OrderDetailView";
import PendingStatusView from "../../../components/PendingStatusView";
import ResponsiveDrawer from "../../../components/ResponsiveDrawer";
import StockStagedTable from "./components/StockStagedTable";
import Switch from "@mui/material/Switch";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

const ORDERS_PER_PAGE = 20;

const ORDER_PRODUCTION_JOB_QUERY = gql`
  query BarcodeOrderQuery($orderProductionJobId: ID!) {
    node(id: $orderProductionJobId) {
      ... on OrderProductionJob {
        id
        order {
          id
          vendor {
            id
            organization {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const STOCK_CONTAINER_QUERY = gql`
  query StockContainerBarcodeOrderQuery($stockContainerId: ID!) {
    node(id: $stockContainerId) {
      ... on StockContainer {
        id
        orderProductionJob {
          id
          order {
            id
            vendor {
              id
              organization {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const StockStagedApp = ({
  appBarBackgroundColor,
  backUrl,
  showErrorSnackbar,
  showSuccessSnackbar
}) => {
  const client = useApolloClient();
  const { router } = useRouter();

  // Mutations:
  const [markOrderAsUnstaged] = useMutation(markOrderAsUnstagedMutation);
  const [markOrderAsStockStaged] = useMutation(markOrderAsStockStagedMutation);

  // States:
  const [autoPrint, setAutoPrint] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedSortBy, setSortBy] = useState(null);
  const [selectedSortDirection, setSortDirection] = useState(null);
  const [selectedProductionDate, setSelectedProductionDate] = useState(null);
  const [showAllSwitchValue, setShowAllSwitchValue] = useState(null);
  const [loadingOrderIds, setLoadingOrderIds] = useState([]);
  const [drawerDisplayState, setDrawerDisplayState] = useState("closed");

  // Query:
  const { data, loading, error, refetch, fetchMore } = useQuery(query, {
    variables: {
      first: ORDERS_PER_PAGE,
      filters: {
        includeUnassigned: true,
        includeStaged: searchQuery ? true : showAllSwitchValue,
        productionDate:
          selectedProductionDate === "All" ? null : selectedProductionDate,
        searchQuery: searchQuery,
        sortBy: selectedSortBy,
        sortDirection: selectedSortDirection
      }
    }
  });

  const formattedProductionDates = useMemo(() => {
    if (data?.ordersToBeStaged?.productionDates) {
      const formattedProductionDates =
        data.ordersToBeStaged.productionDates.map(productionDate => ({
          value: productionDate,
          label: DateTime.fromISO(productionDate).toLocaleString(
            DateTime.DATE_SHORT
          )
        }));

      return [{ value: "All", label: "All" }, ...formattedProductionDates];
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (!loading && !error) {
      if (
        data.ordersToBeStaged.edges.length === 1 &&
        data.ordersToBeStaged.edges[0].node.id !== selectedOrderId
      ) {
        setSelectedOrderId(data.ordersToBeStaged.edges[0].node.id);
        setDrawerDisplayState("open-partial");
      }
    }
  }, [data, loading, error, setDrawerDisplayState, selectedOrderId]);

  // Handlers:
  const addLoadingOrderId = orderId => {
    setLoadingOrderIds([...loadingOrderIds, orderId]);
  };

  const removeLoadingOrderId = orderId => {
    const indexToRemove = loadingOrderIds.indexOf(orderId);
    setLoadingOrderIds([
      ...loadingOrderIds.slice(0, indexToRemove),
      ...loadingOrderIds.slice(indexToRemove + 1, loadingOrderIds.length)
    ]);
  };

  const handleBarcodeScan = barcode => {
    const data = JSON.parse(barcode);

    if (
      data.variant === "OBJECT" &&
      data.type === "OrderProductionJob" &&
      data.id
    ) {
      setSearchQuery(null);
      client
        .query({
          query: ORDER_PRODUCTION_JOB_QUERY,
          variables: { orderProductionJobId: data.id }
        })
        .then(
          ({
            data: {
              node: {
                order: { id, vendor }
              }
            }
          }) => {
            markOrderAsStockStaged({
              variables: { orderId: id }
            })
              .then(
                ({
                  data: {
                    markOrderAsStockStaged: { succeeded, order, errors }
                  }
                }) => {
                  if (succeeded) {
                    setAutoPrint(true);
                    setSelectedOrderId(order.id);
                    setDrawerDisplayState("open-partial");
                    showSuccessSnackbar("Order status has been staged.");
                  } else {
                    const { orderId: error } = errors;
                    if (vendor) {
                      showErrorSnackbar(
                        `Return Goods. Order Assigned to ${vendor.organization.name}`
                      );
                      playAudioForReturnStock();
                    } else {
                      showErrorSnackbar(error);
                    }
                  }
                }
              )
              .catch(e => {
                showErrorSnackbar(e.message);
              })
              .finally(() => setAutoPrint(false));
          }
        );
    } else if (
      data.variant === "OBJECT" &&
      data.type === "StockContainer" &&
      data.id
    ) {
      setSearchQuery(null);
      client
        .query({
          query: STOCK_CONTAINER_QUERY,
          variables: { stockContainerId: data.id }
        })
        .then(
          ({
            data: {
              node: {
                orderProductionJob: {
                  order: { id, vendor }
                }
              }
            }
          }) => {
            markOrderAsStockStaged({
              variables: { orderId: id }
            })
              .then(
                ({
                  data: {
                    markOrderAsStockStaged: { succeeded, order, errors }
                  }
                }) => {
                  if (succeeded) {
                    setAutoPrint(true);
                    setSelectedOrderId(order.id);
                    setDrawerDisplayState("open-partial");
                    showSuccessSnackbar("Order status has been staged.");
                  } else {
                    const { orderId: error } = errors;
                    showErrorSnackbar(
                      `Return Goods. Order Assigned to ${vendor.organization.name}`
                    );
                    playAudioForReturnStock();
                    showErrorSnackbar(error);
                  }
                }
              )
              .catch(e => {
                showErrorSnackbar(e.message);
              });
          }
        );
    }
  };

  const handleBarcodeError = () => showErrorSnackbar("Error scanning barcode");

  const handleSearch = searchQuery => {
    setSelectedOrderId(null);
    setSearchQuery(searchQuery);
  };

  const handleStockStagedTableRowClicked = orderId => {
    setSelectedOrderId(orderId);
    setDrawerDisplayState("open-partial");
  };

  const handleShowAllSwitchChanged = (event, showAll) => {
    setShowAllSwitchValue(showAll);
    setHasNextPage(true);

    refetch({
      first: ORDERS_PER_PAGE,
      filters: {
        productionDate:
          selectedProductionDate === "All" ? null : selectedProductionDate,
        includeUnassigned: true,
        includeStaged: showAll
      }
    });
  };

  const handleAppHeaderRequestBack = () =>
    router.push(backUrl ? backUrl : "/apps");

  const handleAppHeaderTabSelected = selectedProductionDate => {
    setHasNextPage(true);
    setSelectedProductionDate(selectedProductionDate);

    refetch({
      first: ORDERS_PER_PAGE,
      filters: {
        productionDate:
          selectedProductionDate === "All" ? null : selectedProductionDate,
        includeUnassigned: true,
        includeStaged: showAllSwitchValue
      }
    });
  };

  const handleStockStagedTableScrolledToBottom = () => {
    if (isLoadingMore || !hasNextPage) return;

    setIsLoadingMore(true);
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.ordersToBeStaged.edges;
        const pageInfo = fetchMoreResult.ordersToBeStaged.pageInfo;
        setHasNextPage(pageInfo.hasNextPage);
        return newEdges.length
          ? {
              ordersToBeStaged: {
                pageInfo,
                __typename: previousResult.ordersToBeStaged.__typename,
                edges: [...previousResult.ordersToBeStaged.edges, ...newEdges],
                productionDates:
                  fetchMoreResult.ordersToBeStaged.productionDates
              }
            }
          : previousResult;
      },
      variables: {
        after: data.ordersToBeStaged.pageInfo.endCursor,
        filters: {
          productionDate:
            selectedProductionDate === "All" ? null : selectedProductionDate,
          includeUnassigned: true,
          includeStaged: showAllSwitchValue
        }
      }
    }).finally(() => setIsLoadingMore(false));
  };

  const handleSort = newSelectedSortBy => {
    setSortBy(newSelectedSortBy);
    if (selectedSortBy === newSelectedSortBy) {
      selectedSortDirection === "ASCENDING"
        ? setSortDirection("DESCENDING")
        : setSortDirection("ASCENDING");
    } else {
      setSortDirection("ASCENDING");
    }
  };

  const processRequestStockStaged = orderId => {
    addLoadingOrderId(orderId);
    markOrderAsStockStaged({ variables: { orderId } })
      .then(
        ({
          data: {
            markOrderAsStockStaged: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar("Order status has been updated.");
          } else {
            showErrorSnackbar(errors.orderId.join(", "));
          }
        }
      )
      .catch(e => {
        showErrorSnackbar(e.message);
      })
      .finally(() => removeLoadingOrderId(orderId));
  };

  const processRequestStockUnstaged = orderId => {
    addLoadingOrderId(orderId);
    markOrderAsUnstaged({ variables: { orderId } })
      .then(
        ({
          data: {
            markOrderAsUnstaged: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar("Order status has been updated.");
          } else {
            showErrorSnackbar(errors.orderId.join(", "));
          }
        }
      )
      .catch(e => {
        showErrorSnackbar(e.message);
      })
      .finally(() => removeLoadingOrderId(orderId));
  };

  return (
    <React.Fragment>
      <div>
        <TabbedAppBar
          title="Counting / Staging"
          tabItems={
            data && data.ordersToBeStaged ? formattedProductionDates : []
          }
          onRequestBack={handleAppHeaderRequestBack}
          onSearch={handleSearch}
          onTabSelected={handleAppHeaderTabSelected}
          appBarBackgroundColor={appBarBackgroundColor}
          menuItems={[
            <MenuItem>
              Show All
              <Switch
                checked={showAllSwitchValue}
                onChange={handleShowAllSwitchChanged}
                color="primary"
              />
            </MenuItem>
          ]}
        />
        {loading ? (
          <PendingStatusView status="Loading" />
        ) : error ? (
          <Typography variant="body2" color="error">
            {error.message}
          </Typography>
        ) : (
          <div>
            <BarcodeReader
              onError={handleBarcodeError}
              onScan={handleBarcodeScan}
            />
            <StockStagedTable
              loadingOrderIds={loadingOrderIds}
              onScrolledToBottom={handleStockStagedTableScrolledToBottom}
              orders={filter(
                stockStagedTableFragments.order,
                data.ordersToBeStaged.edges.map(edge => ({ ...edge.node }))
              )}
              onRequestStockStaged={processRequestStockStaged}
              onRequestUnstaged={processRequestStockUnstaged}
              onRequestSort={handleSort}
              onRowClicked={handleStockStagedTableRowClicked}
            />
            {isLoadingMore && hasNextPage && <CenteredSpinner />}
          </div>
        )}
      </div>
      <ResponsiveDrawer
        displayState={drawerDisplayState}
        onRequestOpenFull={() => console.log("handleDrawerRequestOpenFull")}
        onRequestClose={() => {
          setDrawerDisplayState("closed");
          setSelectedOrderId(null);
        }}
        title={"Job Details"}
      >
        <OrderDetailView
          autoPrint={autoPrint}
          onRequestStockStaged={selectedOrderId => {
            setDrawerDisplayState("closed");
            processRequestStockStaged(selectedOrderId);
          }}
          onRequestUnstaged={selectedOrderId => {
            setDrawerDisplayState("closed");
            processRequestStockUnstaged(selectedOrderId);
          }}
          orderId={selectedOrderId}
          isStagingFromStock={loadingOrderIds.includes(selectedOrderId)}
        />
      </ResponsiveDrawer>
    </React.Fragment>
  );
};

export default withSnackbar(StockStagedApp);
