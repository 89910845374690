import * as React from "react";
import { fragments as designProofListItemFragments } from "./components/DesignProofListItem/graph";
import { filter } from "graphql-anywhere";
import { makeStyles } from "@mui/styles";
import DesignProofListItem from "./components/DesignProofListItem";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(() => ({
  list: {
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  item: {
    listStyleType: "none",
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 0
  }
}));

const DesignProofList = ({ proofs, flipArtwork = false }) => {
  const classes = useStyles();

  if (proofs.length === 0) {
    return <Typography variant="body2">No artwork.</Typography>;
  }

  return (
    <ul className={classes.list}>
      {proofs.map(proof => (
        <li className={classes.item} key={proof.id}>
          <DesignProofListItem
            flipArtwork={flipArtwork}
            proof={filter(designProofListItemFragments.proof, proof)}
          />
        </li>
      ))}
    </ul>
  );
};

export default DesignProofList;
