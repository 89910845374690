import * as React from "react";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import invariant from "invariant";

const useStyles = makeStyles(() => ({
  avatar: {
    height: 40,
    width: 40
  },
  chip: {
    height: 32,
    width: 32
  }
}));

const PersonImageAvatar = ({ name, photographUrl, size, ...props }) => {
  const classes = useStyles();

  let sizeClass = null;
  switch (size) {
    case "avatar":
      sizeClass = classes.avatar;
      break;
    case "chip":
      sizeClass = classes.chip;
      break;
    default:
      sizeClass = null;
      break;
  }

  invariant(sizeClass != null, "sizeClass should not be null");

  return (
    <Avatar src={photographUrl} alt={name} className={sizeClass} {...props} />
  );
};

export default PersonImageAvatar;
