import * as React from "react";
import { gql, useQuery } from "@apollo/client";
import BarcodePivot from "../components/BarcodePivot";
import PendingStatusView from "../components/PendingStatusView";
import config from "./config";

const Root = props => {
  const { data, loading } = useQuery(gql`
    query {
      viewer {
        id
        isAuthenticated
      }
    }
  `);

  if (!data || loading) {
    return <PendingStatusView status="Authenticating" />;
  }

  if (!data.viewer || !data.viewer.isAuthenticated) {
    window.location = `${config.getFlynetCoreLoginUrl()}?client_id=${encodeURIComponent(config.getFlynetCoreClientId())}&return_to=${encodeURIComponent(window.location.href)}`;
    return null;
  }

  return <BarcodePivot>{props.children}</BarcodePivot>;
};

export default Root;
