// @flow

import * as React from "react";
import { Container, Typography } from "@mui/material";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withRouter } from "found";
import AppBar from "../../../components/AppBar";
import ArtReviewOrderDetails from "./components/ArtReviewOrderDetails";
import PendingStatusView from "../../../components/PendingStatusView/PendingStatusView";
import type { HOC } from "recompose";

const ORDERS_PER_PAGE = 20;

const enhancer: HOC<*, *> = compose(
  setDisplayName("ArtReviewApp"),

  withRouter,

  withStateHandlers(
    { after: null },
    {
      setAfter: () => after => ({
        after
      })
    }
  ),

  graphql(query, {
    options: () => ({
      variables: {
        first: ORDERS_PER_PAGE
      },
      fetchPolicy: "network-only"
    })
  }),

  withHandlers({
    handleAppBarRequestBack:
      ({ router, backUrl }) =>
      () => {
        router.push(backUrl ? backUrl : "/apps");
      }
  })
);

const ArtReviewApp = ({
  after,
  data,
  handleAppBarRequestBack,
  appBarBackgroundColor,
  setAfter
}) => {
  if (
    !data.loading &&
    !data.error &&
    !after &&
    data.ordersConnection.edges.length > 0
  ) {
    const startIndex = Math.floor(
      Math.random() * data.ordersConnection.edges.length
    );
    const { cursor } = data.ordersConnection.edges[startIndex];
    setAfter(cursor);
    return null;
  }

  const [orderId] =
    !data.loading && !data.error
      ? data.ordersConnection.edges
          .filter(({ cursor }) => cursor === after)
          .map(({ node: { id } }) => id)
      : [null];

  const handleOnComplete = () => {
    setAfter(null);
    data.refetch();
  };

  return (
    <div>
      <AppBar
        title="Art Review"
        onRequestBack={handleAppBarRequestBack}
        backgroundColor={appBarBackgroundColor}
      />
      {data.loading ? (
        <PendingStatusView status="Loading..." />
      ) : (
        <div>
          {data.error ? (
            <h6>{data.error}</h6>
          ) : (
            <>
              {!orderId ? (
                <Container>
                  <br />
                  <br />
                  <Typography variant="h2" align="center">
                    All Art Review Complete
                  </Typography>
                </Container>
              ) : (
                <div>
                  <ArtReviewOrderDetails
                    orderId={orderId}
                    onComplete={handleOnComplete}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default enhancer(ArtReviewApp);
