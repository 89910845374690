import * as React from "react";
import Chip from "@mui/material/Chip";

import PersonAvatar from "../PersonAvatar/PersonAvatar";

const EmployeeChip = ({ employee: { name_employeeChipModule } }) => (
  <Chip
    avatar={<PersonAvatar name={name_employeeChipModule} size="chip" />}
    label={`${
      name_employeeChipModule === "" ? "N/A" : name_employeeChipModule
    }`}
  />
);

export default EmployeeChip;
