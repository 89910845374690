import { gql } from "@apollo/client";

const markOrderAsStockStagedMutation = gql`
  mutation($orderId: ID!) {
    markOrderAsStockStaged(orderId: $orderId) {
      errors {
        orderId
      }
      order {
        id
        isStockStaged
      }
      succeeded
    }
  }
`;

export default markOrderAsStockStagedMutation;
