import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PubNub from "pubnub";
import React, { useCallback, useEffect, useRef, useState } from "react";
import config from "../../application/config";

const CurrentViewers = ({ channel, viewer }) => {
  const [viewers, setViewers] = useState([]);
  const pubnubRef = useRef(null);

  useEffect(() => {
    const uuid = viewer.me.id.substr(viewer.me.id.length - 16);
    pubnubRef.current = new PubNub({
      ...config.getPubNubKeys(),
      uuid: uuid
    });

    return () => {
      pubnubRef.current?.unsubscribe({ channels: [channel] });
    };
  }, [viewer, channel]);

  const refresh = useCallback(() => {
    if (!pubnubRef.current) return;

    pubnubRef.current.hereNow(
      {
        channels: [channel],
        includeUUIDs: true,
        includeState: true
      },
      (status, response) => {
        if (response) {
          const occupants = response.channels[channel].occupants
            .map(({ state }) => state)
            .filter(({ id }) => id !== viewer.me.id);
          setViewers(occupants);
        }
      }
    );
  }, [channel, viewer]);

  useEffect(() => {
    if (!pubnubRef.current) return;

    pubnubRef.current.addListener({
      presence: refresh,
      message: refresh,
      signal: refresh
    });

    pubnubRef.current.setState({
      state: {
        name: viewer.me.name,
        id: viewer.me.id
      },
      channels: [channel]
    });

    pubnubRef.current.subscribe({
      channels: [channel],
      withPresence: true
    });

    pubnubRef.current.publish({
      message: "here",
      channel: channel
    });

    refresh();

    return () => {
      pubnubRef.current?.unsubscribe({ channels: [channel] });
    };
  }, [channel, viewer, refresh]);

  return (
    <List dense={false}>
      {viewers.map(({ name }, index) => (
        <ListItem key={index}>
          <ListItemText primary={name} />
        </ListItem>
      ))}
    </List>
  );
};

export default CurrentViewers;
