import * as React from "react";
import { fragments as customizationAreaArtworkListItemFragments } from "./components/CustomizationAreaArtworkListItem/graph";
import { filter } from "graphql-anywhere";
import { makeStyles } from "@mui/styles";
import CustomizationAreaArtworkListItem from "./components/CustomizationAreaArtworkListItem";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(() => ({
  list: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    listStyle: "none",
    margin: 0,
    padding: 0
  },

  item: {
    listStyleType: "none",
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 0
  }
}));

const CustomizationAreaArtworkList = ({ proofs, flipArtwork, onClick }) => {
  const classes = useStyles();

  if (proofs.length === 0) {
    return <Typography variant="body2">No artwork.</Typography>;
  }

  return (
    <ul className={classes.list}>
      {proofs.map(proof => (
        <li className={classes.item} key={proof.id}>
          <CustomizationAreaArtworkListItem
            proof={filter(
              customizationAreaArtworkListItemFragments.proof,
              proof
            )}
            flipArtwork={flipArtwork}
            onClick={onClick}
          />
        </li>
      ))}
    </ul>
  );
};

export default CustomizationAreaArtworkList;
