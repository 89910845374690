import { gql } from "@apollo/client";

const markOrderAsUnstagedMutation = gql`
  mutation($orderId: ID!) {
    markOrderAsUnstaged(orderId: $orderId) {
      errors {
        orderId
      }
      updatedOrder {
        id
        isStockStaged
      }
      succeeded
    }
  }
`;

export default markOrderAsUnstagedMutation;
