import { filter } from "graphql-anywhere";
import { markOrderAsScreensMadeMutation } from "../../../graph";
import { query } from "./graph";
import { fragments as screensMadeTableFragments } from "./components/ScreensMadeTable/graph";
import { useMutation, useQuery } from "@apollo/client";
import { useRouter } from "found";
import CenteredSpinner from "../../../components/CenteredSpinner";
import PendingStatusView from "../../../components/PendingStatusView";
import React, { useCallback, useState } from "react";
import ScreensMadeTable from "./components/ScreensMadeTable";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

const ORDERS_PER_PAGE = 1000;

const ScreensMadeApp = ({ showErrorSnackbar, showSuccessSnackbar }) => {
  const { router } = useRouter();

  // States:
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadingOrderIds, setLoadingOrderIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedSortBy, setSelectedSortBy] = useState(null);
  const [selectedSortDirection, setSelectedSortDirection] = useState(null);

  const { data, loading, error, fetchMore } = useQuery(query, {
    variables: {
      first: ORDERS_PER_PAGE,
      filters: {
        searchQuery,
        sortBy: selectedSortBy,
        sortDirection: selectedSortDirection
      }
    }
  });

  // Mutations:
  const [markOrderAsScreensMade] = useMutation(markOrderAsScreensMadeMutation);

  // Handlers:
  const handleAppHeaderRequestBack = useCallback(() => {
    router.push("/apps");
  }, [router]);

  const handleSearch = useCallback(query => {
    setSearchQuery(query);
  }, []);

  const handleScreensMadeTableScrolledToBottom = useCallback(() => {
    if (isLoadingMore || !hasNextPage) return;

    setIsLoadingMore(true);

    fetchMore({
      variables: { after: data?.ordersToBeScreened.pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.ordersToBeScreened.edges || [];
        const pageInfo = fetchMoreResult?.ordersToBeScreened.pageInfo || {};

        setHasNextPage(pageInfo.hasNextPage);

        return newEdges.length
          ? {
              ordersToBeScreened: {
                ...previousResult.ordersToBeScreened,
                edges: [
                  ...previousResult.ordersToBeScreened.edges,
                  ...newEdges
                ],
                pageInfo
              }
            }
          : previousResult;
      }
    }).finally(() => setIsLoadingMore(false));
  }, [isLoadingMore, hasNextPage, fetchMore, data]);

  const handleScreensMadeTableRequestMakeScreen = useCallback(
    orderId => {
      setLoadingOrderIds(prev => [...prev, orderId]);

      markOrderAsScreensMade({ variables: { orderId } })
        .then(({ data }) => {
          if (data?.markOrderAsScreensMade?.succeeded) {
            showSuccessSnackbar("Order status has been updated.");
          } else {
            showErrorSnackbar(
              data?.markOrderAsScreensMade?.errors?.orderId.join(", ")
            );
          }
        })
        .catch(e => showErrorSnackbar(e.message))
        .finally(() =>
          setLoadingOrderIds(prev => prev.filter(id => id !== orderId))
        );
    },
    [markOrderAsScreensMadeMutation, showErrorSnackbar, showSuccessSnackbar]
  );

  const handleSort = useCallback(
    newSelectedSortBy => {
      setSelectedSortBy(newSelectedSortBy);
      if (selectedSortBy === newSelectedSortBy) {
        setSelectedSortDirection(prev =>
          prev === "ASCENDING" ? "DESCENDING" : "ASCENDING"
        );
      } else {
        setSelectedSortDirection("ASCENDING");
      }
    },
    [selectedSortBy]
  );

  return (
    <div>
      <TabbedAppBar
        title="Screens Made"
        onRequestBack={handleAppHeaderRequestBack}
        onSearch={handleSearch}
      />
      {loading ? (
        <PendingStatusView status="Loading" />
      ) : error ? (
        <Typography variant="body2" color="error">
          {error.message}
        </Typography>
      ) : (
        <div>
          <ScreensMadeTable
            onScrolledToBottom={handleScreensMadeTableScrolledToBottom}
            loadingOrderIds={loadingOrderIds}
            onRequestMakeScreen={handleScreensMadeTableRequestMakeScreen}
            onRequestSort={handleSort}
            orders={filter(
              screensMadeTableFragments.order,
              data?.ordersToBeScreened?.edges?.map(edge => ({
                ...edge.node
              })) || []
            )}
          />
          {isLoadingMore && hasNextPage && <CenteredSpinner />}
        </div>
      )}
    </div>
  );
};

export default withSnackbar(ScreensMadeApp);
