import invariant from "invariant";

const clientId = process.env.REACT_APP_CLIENT_ID;
const loginUrl = process.env.REACT_APP_LOGIN_URL;
const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
const pubNubPublishKey = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
const pubNubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
const flynetCoreProductionGraphUrl =
  process.env.REACT_APP_FLYNET_CORE_PRODUCTION_GRAPH_URL;

const getPubNubKeys = () => {
  invariant(!!pubNubPublishKey, "REACT_APP_PUBNUB_PUBLISH_KEY must be set");
  invariant(!!pubNubSubscribeKey, "REACT_APP_PUBNUB_SUBSCRIBE_KEY must be set");
  return {
    publishKey: pubNubPublishKey,
    subscribeKey: pubNubSubscribeKey
  };
};

const getFlynetCoreProductionGraphUrl = () => {
  return flynetCoreProductionGraphUrl ? flynetCoreProductionGraphUrl : "";
};

const getFlynetCoreClientId = () => {
  invariant(!!clientId, "REACT_APP_CLIENT_ID must be set");
  return clientId;
};

const getFlynetCoreLoginUrl = () => {
  invariant(!!loginUrl, "REACT_APP_LOGIN_URL must be set");
  return loginUrl;
};

const getFlynetCoreLogoutUrl = () => {
  invariant(!!logoutUrl, "REACT_APP_LOGOUT_URL must be set");
  return logoutUrl;
};

export default {
  getFlynetCoreLoginUrl,
  getFlynetCoreLogoutUrl,
  getFlynetCoreClientId,
  getFlynetCoreProductionGraphUrl,
  getPubNubKeys
};
