import * as React from "react";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const styles = theme => ({
  container: {
    padding: theme.spacing(8),
    position: "relative",
    textAlign: "center"
  },

  status: {
    paddingTop: theme.spacing(4)
  }
});

const PendingStatusView = ({ classes, status }) => (
  <div className={classes.container}>
    <CircularProgress size={80} />
    <Typography className={classes.status}>{status}</Typography>
  </div>
);

export default withStyles(styles)(PendingStatusView);
