import * as React from "react";
import Typography from "@mui/material/Typography";

const UnformattedNote = ({ note }) => (
  <React.Fragment>
    {note.split("\n").map((line, index) => (
      <Typography key={index}>{line}</Typography>
    ))}
  </React.Fragment>
);

export default UnformattedNote;
