import * as React from "react";
import { query } from "./graph";
import { useApolloClient } from "@apollo/client";
import { useRouter } from "found";
import { useState } from "react";
import BarcodeReader from "react-barcode-reader";
import PendingStatusView from "../../../components/PendingStatusView";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";

const FilmCheckApp = ({
  appBarBackgroundColor,
  backUrl,
  showErrorSnackbar
}) => {
  const { router } = useRouter();
  const client = useApolloClient();

  // States:
  const [isLoading, setIsLoading] = useState(false);
  const [screenLocation, setScreenLocation] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [highDefinitionDigitalStatus, setHighDefinitionDigitalStatus] =
    useState(null);

  // Handlers:
  const handleAppHeaderRequestBack = () => router.push(backUrl || "/apps");

  const handleBarcodeError = () => {
    showErrorSnackbar("Error scanning barcode");
    setIsLoading(false);
  };

  const handleBarcodeScan = barcode => {
    try {
      const data = JSON.parse(barcode);
      const { variant, type, id } = data;
      if (variant === "OBJECT" && type === "OrderProductionJob" && id) {
        setIsLoading(true);
        client
          .query({
            query,
            variables: { id },
            fetchPolicy: "network-only"
          })
          .then(({ data: { node } }) => {
            if (node) {
              const {
                order: { orderNumber, highDefinitionDigitalStatus },
                latestScreenLocation
              } = node;
              setScreenLocation(latestScreenLocation?.location);
              setOrderNumber(orderNumber);
              setHighDefinitionDigitalStatus(highDefinitionDigitalStatus);
            } else {
              setScreenLocation(null);
              setOrderNumber(null);
              setHighDefinitionDigitalStatus(null);
              showErrorSnackbar("Order not found.");
            }
          })
          .catch(e => {
            showErrorSnackbar(e.message);
          })
          .finally(() => setIsLoading(false));
      }
    } catch {
      setIsLoading(false);
      showErrorSnackbar("Error Scanning Barcode");
    }
  };

  return (
    <div>
      <TabbedAppBar
        title="Audit Film Status"
        appBarBackgroundColor={appBarBackgroundColor}
        onRequestBack={handleAppHeaderRequestBack}
      />
      {isLoading ? (
        <PendingStatusView status="Loading" />
      ) : (
        <div>
          <BarcodeReader
            onError={handleBarcodeError}
            onScan={handleBarcodeScan}
          />
          {orderNumber ? (
            <React.Fragment>
              <Typography
                variant="h6"
                align="center"
                color="inherit"
                style={{ flexGrow: 1, marginTop: "10em" }}
              >
                Order #: {orderNumber}
              </Typography>
              <Typography variant="h6" align="center" color="inherit">
                HDD Status: {highDefinitionDigitalStatus}
              </Typography>
              <Typography variant="h6" align="center" color="inherit">
                Film Location: {screenLocation}
              </Typography>
            </React.Fragment>
          ) : (
            <Typography
              variant="h6"
              align="center"
              color="inherit"
              style={{ flexGrow: 1, marginTop: "10em" }}
            >
              Scan Staging Label to check Film Location
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default withSnackbar(FilmCheckApp);
