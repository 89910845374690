import * as React from "react";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTableCell from "../../../../../components/CustomTableCell";
import PriorityChip from "../../../../../components/PriorityChip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useScrollPosition from "../../../../../hooks/useScrollPosition";

const useStyles = makeStyles(theme => ({
  contactColumn: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  contactOrderNumberWrapper: {
    width: 225,
    margin: "0 auto",
    wordWrap: "break-word",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20%",
      marginRight: theme.spacing(1)
    }
  },

  dateColumn: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  poColumn: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  quantityColumn: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0
    },
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  },

  stagedColumn: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    textAlign: "center"
  },

  stationColumn: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0
    },
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  }
}));

const StockStagedTable = ({
  loadingOrderIds,
  onRequestSort,
  onRequestStockStaged,
  onRequestUnstaged,
  onRowClicked,
  onScrolledToBottom,
  orders
}) => {
  const classes = useStyles();

  const { isScrolledToBottom } = useScrollPosition();
  useEffect(() => {
    if (isScrolledToBottom) {
      onScrolledToBottom();
    }
  });

  // Handlers:
  const handleStockStagedButtonClick = event => {
    event.stopPropagation();
    onRequestStockStaged(event.currentTarget.id);
  };

  const handleStockUnstagedButtonClick = event => {
    event.stopPropagation();
    onRequestUnstaged(event.currentTarget.id);
  };

  const handleTableRowClicked = id => onRowClicked(id);

  const handleSortRequest = event => onRequestSort(event.currentTarget.id);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <CustomTableCell className={classes.contactColumn}>
            <div className={classes.contactOrderNumberWrapper}>
              <Button id="CUSTOMER_NAME" onClick={handleSortRequest}>
                <Typography color="textSecondary" variant="overline">
                  Customer/Order #
                </Typography>
              </Button>
            </div>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.poColumn}>
            <Typography color="textSecondary" variant="overline">
              PO
            </Typography>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.quantityColumn}>
            <Button
              id="TOTAL_PRODUCT_VARIANT_QUANTITY"
              onClick={handleSortRequest}
            >
              <Typography color="textSecondary" variant="overline">
                Quantity
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.dateColumn}>
            <Button id="PRODUCTION_DATE" onClick={handleSortRequest}>
              <Typography color="textSecondary" variant="overline">
                Production Date
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.stationColumn}>
            <Button
              id="PRODUCTION_STATION_ASSIGNMENT"
              onClick={handleSortRequest}
            >
              <Typography color="textSecondary" variant="overline">
                S/A
              </Typography>
            </Button>
          </CustomTableCell>
          <CustomTableCell align="center" className={classes.stagedColumn}>
            <Typography color="textSecondary" variant="overline">
              Stock Staged
            </Typography>
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map(order => (
          <TableRow
            key={order.id}
            onClick={handleTableRowClicked.bind(null, order.id)}
          >
            <TableCell className={classes.contactColumn} scope="row">
              <div className={classes.contactOrderNumberWrapper}>
                <Typography variant="h6">
                  {order.primaryCustomer.name}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {order.orderNumber}
                </Typography>
              </div>
            </TableCell>
            <TableCell align="center" className={classes.poColumn}>
              <Typography align="center" variant="p">
                {order.productionJobs.map(({ label }) => label).join(",")}
              </Typography>
            </TableCell>
            <TableCell align="center" className={classes.quantityColumn}>
              <Typography variant="p">
                {order.totalProductVariantQuantity}
              </Typography>
            </TableCell>
            <TableCell align="center" className={classes.dateColumn}>
              {order.productionDate && (
                <PriorityChip
                  date={order.productionDate}
                  priority={order.priority}
                />
              )}
            </TableCell>
            <TableCell align="center" className={classes.stationColumn}>
              {order.productionStationAssignments.map((assignment, index) => (
                <Typography key={index} variant="p">
                  {assignment.productionStation.name}
                </Typography>
              ))}
            </TableCell>
            <TableCell align="center" className={classes.stagedColumn}>
              {loadingOrderIds.includes(order.id) ? (
                <CircularProgress />
              ) : order.isStockStaged ? (
                <Button
                  id={order.id}
                  variant="contained"
                  color="success"
                  onClick={handleStockUnstagedButtonClick}
                >
                  Unstage
                </Button>
              ) : (
                <Button
                  id={order.id}
                  variant="contained"
                  color="primary"
                  onClick={handleStockStagedButtonClick}
                >
                  Staged
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default StockStagedTable;
