// @flow

import { fragments as automatedNotesWrapperFragments } from "../AutomatedNotesWrapper/graph";
import {
  compose,
  setDisplayName,
  withHandlers,
  withPropsOnChange,
  withStateHandlers
} from "recompose";
import { filter } from "graphql-anywhere";
import { fragments as notesWrapperFragments } from "../NotesWrapper/graph";
import { fragments as orderStatusHeaderFragments } from "../OrderStatusHeader/graph";
import { fragments as productionJobDetailFragments } from "../ProductionJobDetail/graph";
import { fragments as productionJobListFragments } from "../ProductionJobList/graph";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import AutomatedNotesWrapper from "../AutomatedNotesWrapper";
import CurrentViewers from "../CurrentViewers";
import NotesWrapper from "../NotesWrapper";
import OrderStatusHeader from "../OrderStatusHeader";
import OrderStatusHistorySummary from "../../application/apps/OrderApp/components/OrderStatusHistorySummary";
import PendingStatusView from "../PendingStatusView";
import ProductionJobDetail from "../ProductionJobDetail";
import ProductionJobList from "../ProductionJobList";
import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import withBarcodePivot from "../BarcodePivot/withBarcodePivot";

import type { HOC } from "recompose";

type Props = {|
  +children?: React.ChildrenArray<any>,
  +classes?: {|
    +absoluteWrapper: string,
    +appBarWrapper: string,
    +content: string,
    +notesHistoryContainer: string,
    +productionJobDetailContainer: string,
    +productionJobListContainer: string,
    +scrollableContent: string,
    +tab: string
  |},
  +errorMessage: ?string,
  +isArtApp?: boolean,
  +loading: boolean,
  +onRequestClose: () => void,
  +order: {|
    +productionJobs: $ReadOnlyArray<{}>
  |},
  +orderStatusView: React.Element<any>,
  +router?: {|
    +push: (location: string) => void
  |},
  +shouldShowClaimButton?: boolean,
  +shouldShowCustomerArtworkWithNotes: boolean,
  +shouldShowInkColors: boolean,
  +shouldShowSalesNotes?: boolean,
  +title: string,
  +viewer: {|
    +me: {| +id: string, +name: string |}
  |}
|};

type State = {|
  selectedAuxiliaryTabIndex: number,
  selectedProductionJobIndex: number
|};

const defaultState: State = {
  selectedAuxiliaryTabIndex: 0,
  selectedProductionJobIndex: 0
};

const styles = theme => ({
  absoluteWrapper: {
    position: "absolute",
    top: 48,
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },

  appBarWrapper: {
    zIndex: 1
  },

  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: 0
  },

  scrollableContent: {
    overflow: "auto",
    minHeight: 0,
    display: "flex"
  },

  productionJobListContainer: {
    overflowY: "scroll",
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "100%",
    flex: "1 1 15%",
    minWidth: 300
  },

  notesHistoryContainer: {
    flex: "1 1 25%",
    overflowY: "scroll"
  },

  productionJobDetailContainer: {
    flex: "1 1 60%",
    overflowY: "scroll",
    borderRight: `1px solid ${theme.palette.divider}`
  },

  tab: {
    color:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0.7)"
        : "rgba(0, 0, 0, 0.54)",
    margin: theme.spacing(1)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("OrderDetailWithStatusHeader"),

  withRouter,

  withBarcodePivot,

  withStyles(styles),

  withStateHandlers(defaultState, {
    setSelectedAuxiliaryTabIndex:
      () => (selectedAuxiliaryTabIndex: number) => ({
        selectedAuxiliaryTabIndex
      }),
    setSelectedProductionJobIndex:
      () => (selectedProductionJobIndex: number) => ({
        selectedProductionJobIndex
      })
  }),

  withHandlers({
    handleAppBarRequestBack:
      ({ router }) =>
      () => {
        router.push("/apps");
      },

    handleAuxiliaryTabChange:
      ({ setSelectedAuxiliaryTabIndex }) =>
      (event, selectedIndex: number) => {
        setSelectedAuxiliaryTabIndex(selectedIndex);
      },

    handleOrderStatusAppBarRequestClose:
      ({ onRequestClose }) =>
      () => {
        onRequestClose();
      },

    handleProductionJobListSelection:
      ({ setSelectedProductionJobIndex }) =>
      (selectedIndex: number) => {
        setSelectedProductionJobIndex(selectedIndex);
      }
  }),

  withPropsOnChange(
    ["lastOrderProductionJobScanned"],
    ({
      acknowledgeOrderProductionJob,
      destinationRoute,
      lastOrderProductionJobScanned,
      router
    }) => {
      if (lastOrderProductionJobScanned) {
        router.replace(
          `${destinationRoute}/${lastOrderProductionJobScanned.order.id}`
        );
        acknowledgeOrderProductionJob();
      }
    }
  ),

  withPropsOnChange(
    ["lastStockContainerScanned"],
    ({
      acknowledgeStockContainer,
      destinationRoute,
      lastStockContainerScanned,
      router
    }) => {
      if (lastStockContainerScanned) {
        router.replace(
          `${destinationRoute}/${lastStockContainerScanned.orderProductionJob.order.id}`
        );
        acknowledgeStockContainer();
      }
    }
  )
);

const OrderDetailWithStatusHeader = ({
  children,
  classes,
  errorMessage,
  handleAuxiliaryTabChange,
  handleOrderStatusAppBarRequestClose,
  handleProductionJobListSelection,
  isArtApp,
  loading,
  order,
  orderStatusView,
  selectedAuxiliaryTabIndex,
  selectedProductionJobIndex,
  shouldShowArtNotes = true,
  shouldShowClaimButton,
  shouldShowCustomerArtworkWithNotes,
  shouldShowInkColors,
  shouldShowSalesNotes,
  viewer
}) => {
  useEffect(() => {
    if (order) {
      const { productionJobs } = order;
      let selectedProductionJobIndex = 0;
      const orderProductionJobId = new URLSearchParams(
        document.location.search
      ).get("orderProductionJobId");
      if (orderProductionJobId) {
        selectedProductionJobIndex = productionJobs
          .map(({ id }) => id)
          .indexOf(orderProductionJobId);
        handleProductionJobListSelection(selectedProductionJobIndex);
      }
    }
  }, [order, handleProductionJobListSelection]);
  return (
    <div>
      {children}
      {loading ? (
        <div>
          <PendingStatusView status="Loading" />
        </div>
      ) : errorMessage ? (
        <Typography align="center" variant="body2" color="error">
          {errorMessage}
        </Typography>
      ) : (
        <div className={classes.absoluteWrapper}>
          <div className={classes.content}>
            <div className={classes.appBarWrapper}>
              <OrderStatusHeader
                onRequestClose={handleOrderStatusAppBarRequestClose}
                order={filter(orderStatusHeaderFragments.order, order)}
                orderStatusView={orderStatusView}
                isArtApp={isArtApp}
              />
            </div>
            <div className={classes.scrollableContent}>
              <div className={classes.productionJobListContainer}>
                <ProductionJobList
                  onProductionJobSelected={handleProductionJobListSelection}
                  productionJobs={filter(
                    productionJobListFragments.productionJobs,
                    order.productionJobs
                  )}
                  selectedIndex={selectedProductionJobIndex}
                  shouldShowClaimButton={shouldShowClaimButton}
                />
              </div>
              <div className={classes.productionJobDetailContainer}>
                <ProductionJobDetail
                  isArtApp={isArtApp}
                  productionJob={filter(
                    productionJobDetailFragments.productionJob,
                    order.productionJobs[selectedProductionJobIndex]
                  )}
                  shouldShowInkColors={shouldShowInkColors}
                  shouldShowCustomerArtworkWithNotes={
                    shouldShowCustomerArtworkWithNotes
                  }
                />
              </div>
              <div className={classes.notesHistoryContainer}>
                <Tabs
                  indicatorColor="primary"
                  onChange={handleAuxiliaryTabChange}
                  scrollButtons="auto"
                  textColor="primary"
                  value={selectedAuxiliaryTabIndex}
                  variant="scrollable"
                >
                  <Tab className={classes.tab} label="Notes" />
                  <Tab
                    className={classes.tab}
                    label={`Automated Notes (${order.automatedNotesCollection.length})`}
                  />
                  <Tab className={classes.tab} label="Status History" />
                  <Tab className={classes.tab} label="Current Viewers" />
                </Tabs>
                {selectedAuxiliaryTabIndex === 0 && (
                  <div>
                    <NotesWrapper
                      shouldShowArtNotes={shouldShowArtNotes}
                      shouldShowSalesNotes={isArtApp && shouldShowSalesNotes}
                      order={filter(notesWrapperFragments.order, order)}
                    />
                  </div>
                )}
                {selectedAuxiliaryTabIndex === 1 && (
                  <div>
                    <AutomatedNotesWrapper
                      order={filter(
                        automatedNotesWrapperFragments.order,
                        order
                      )}
                    />
                  </div>
                )}
                {selectedAuxiliaryTabIndex === 2 && (
                  <div>
                    <OrderStatusHistorySummary orderId={order.id} />
                  </div>
                )}
                <div
                  style={{
                    display: selectedAuxiliaryTabIndex === 3 ? "block" : "none"
                  }}
                >
                  <CurrentViewers channel={order.id} viewer={viewer} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default enhancer(OrderDetailWithStatusHeader);
