import HillelLogo from "../assets/brand-logos/hillel-brand-logo.png";
import InfluxerLogo from "../assets/brand-logos/influxer-logo.png";
import MerchDayLogo from "../assets/brand-logos/merchday-brand-logo.png";
import NinjaCustomsLogo from "../assets/brand-logos/ninjacustoms-logo.png";
import NinjaPodLogo from "../assets/brand-logos/ninjapod-logo.png";
import NinjaPrinthouseLogo from "../assets/brand-logos/ninjaprinthouse-logo.png";
import NinjaTransfersLogo from "../assets/brand-logos/ninjatransfers-logo.png";
import PrintflyOneLogo from "../assets/brand-logos/printflyone-brand-logo.png";
import RushOrderTeesLogo from "../assets/brand-logos/rushordertees-logo.png";
import RyonetLogo from "../assets/brand-logos/ryonet-logo.png";
import SSActivewearPodLogo from "../assets/brand-logos/ssactivewear-logo.png";
import SouthBySeaLogo from "../assets/brand-logos/southbysea-logo.png";
import WhiteInfluxerLogo from "../assets/brand-logos/influxer-logo_white.png";
import WhiteNinjaCustomsLogo from "../assets/brand-logos/ninjacustoms-logo_white.png";
import WhiteNinjaPodLogo from "../assets/brand-logos/ninjapod-logo_white.png";
import WhiteNinjaPrinthouseLogo from "../assets/brand-logos/ninjaprinthouse-logo_white.png";
import WhiteNinjaTransfersLogo from "../assets/brand-logos/ninjatransfers-logo_white.png";
import WhiteRushOrderTeesLogo from "../assets/brand-logos/rushordertees_logo_white.png";
import WhiteRyonetLogo from "../assets/brand-logos/ryonet-logo.png";
import WhiteSSActivewearPodLogo from "../assets/brand-logos/ssactivewear-logo_white.png";
import WhiteSouthBySeaLogo from "../assets/brand-logos/south-by-sea-white.png";

const getLogoForBrandUrl = (brandUrl, theme) => {
  switch (brandUrl) {
    case "hillel.college.ink":
      return HillelLogo;
    case "influxermerch.com":
      switch (theme) {
        case "light":
          return InfluxerLogo;
        case "dark":
          return WhiteInfluxerLogo;
        default:
          return InfluxerLogo;
      }
    case "merchday.com":
      return MerchDayLogo;
    case "printflyone.com":
      return PrintflyOneLogo;
    case "rushordertees.com":
      switch (theme) {
        case "light":
          return RushOrderTeesLogo;
        case "dark":
          return WhiteRushOrderTeesLogo;
        default:
          return RushOrderTeesLogo;
      }
    case "southbysea.com":
      switch (theme) {
        case "light":
          return SouthBySeaLogo;
        case "dark":
          return WhiteSouthBySeaLogo;
        default:
          return SouthBySeaLogo;
      }
    case "ninjacustom.com":
      switch (theme) {
        case "light":
          return NinjaCustomsLogo;
        case "dark":
          return WhiteNinjaCustomsLogo;
        default:
          return NinjaCustomsLogo;
      }
    case "ninjaprinthouse.com":
      switch (theme) {
        case "light":
          return NinjaPrinthouseLogo;
        case "dark":
          return WhiteNinjaPrinthouseLogo;
        default:
          return NinjaPrinthouseLogo;
      }
    case "ninjapod.com":
      switch (theme) {
        case "light":
          return NinjaPodLogo;
        case "dark":
          return WhiteNinjaPodLogo;
        default:
          return NinjaPodLogo;
      }
    case "ninjatransfers.com":
      switch (theme) {
        case "light":
          return NinjaTransfersLogo;
        case "dark":
          return WhiteNinjaTransfersLogo;
        default:
          return NinjaTransfersLogo;
      }
    case "ssactivewear.com":
      switch (theme) {
        case "light":
          return SSActivewearPodLogo;
        case "dark":
          return WhiteSSActivewearPodLogo;
        default:
          return SSActivewearPodLogo;
      }
    case "ryonet.com":
      switch (theme) {
        case "light":
          return RyonetLogo;
        case "dark":
          return WhiteRyonetLogo;
        default:
          return RyonetLogo;
      }
    default:
      return null;
  }
};

export { getLogoForBrandUrl };
