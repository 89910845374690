import * as React from "react";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const styles = theme => ({
  figure: {
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0
  },

  image: {
    maxHeight: "170px",
    maxWidth: "450px",
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    width: "100%"
  },

  tooltip: {
    backgroundColor: "#ffffff",
    padding: 0
  },

  popper: { opacity: 1 },

  zoomedImage: {
    background:
      "repeating-conic-gradient(#cccccc 0% 25%, #ffffff 0% 50%) 50% / 20px 20px",
    maxHeight: "100%",
    maxWidth: "450px"
  },

  flipArtwork: {
    transform: "rotate(180deg)"
  }
});

const CustomizationAreaArtworkListItem = ({
  classes,
  proof: {
    originalFilename,
    asset: {
      url: { formatted }
    }
  },
  onClick = null,
  flipArtwork = false
}) => {
  const handleArtworkClick = e => {
    if (onClick) {
      onClick(e, formatted);
    }
  };

  return (
    <Tooltip
      classes={{
        popper: classes.popper,
        tooltip: classes.tooltip
      }}
      placement="left-start"
      title={
        <img
          className={`${classes.zoomedImage} ${
            flipArtwork ? classes.flipArtwork : ""
          }`}
          src={formatted}
          alt={
            originalFilename
              ? `Design proof for ${originalFilename}`
              : "Design proof preview"
          }
        />
      }
    >
      <figure className={classes.figure}>
        <a
          href={formatted}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleArtworkClick}
          onTouchStart={handleArtworkClick}
        >
          <img
            className={`${classes.image} ${
              flipArtwork ? classes.flipArtwork : ""
            }`}
            src={formatted}
            alt={
              originalFilename
                ? `Design proof for ${originalFilename}`
                : "Design proof preview"
            }
          />
        </a>
        <figcaption>
          <Typography variant="caption" color="textSecondary">
            {originalFilename ? originalFilename : "N/A"}
          </Typography>
        </figcaption>
      </figure>
    </Tooltip>
  );
};

export default withStyles(styles)(CustomizationAreaArtworkListItem);
