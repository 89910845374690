const getLabelForArtworkStatus = status => {
  switch (status) {
    case "APPROVED":
      return "Artwork has been approved";
    case "COMPLETE":
      return "Proof complete";
    case "IN_PROGRESS":
      return "Artwork is in progress";
    case "NOT_APPLICABLE":
      return "Artwork not applicable for this order";
    case "ON_HOLD":
      return "Artwork blocked";
    case "ORDER_ON_HOLD":
      return "Order is locked";
    case "READY":
      return "Artwork is ready to be started";
    case "NOT_READY":
      return "Artwork not ready";
    case "REVIEWED":
      return "Artwork approved and reviewed";
    default:
      return "";
  }
};

export { getLabelForArtworkStatus };
